import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import PostHeader from '@/components/PostHeader'
import RelatedItem from '@/components/RelatedItem'

interface HomeData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      date: string
      featuredHeroImage?: any
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
      postAuthorImage: any
      postAuthorName: string
      postAuthorContent: string
    }
    menu?: any
    quickmenu?: any
    posts: {
      nodes: any
    }
    wp: any
  }
  pageContext: {
    postlang: string
  }
}

const SingleTemplate = ({ data }: HomeData) => {
  // Page data
  const { page, posts, wp } = data

  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
  }

  const postAuthor = {
    name: page?.postAuthorName,
    image: page?.postAuthorImage,
    content: page?.postAuthorContent,
  }

  return (
    <Layout
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      seo={page?.seo}
      uri={page?.uri}
      useBreadCrump={true}
      customBreadCrump={wp?.options?.customBreadCrump}
      archiveUrl={wp?.options?.archiveurl}
    >
      <PostHeader date={page?.date} heading={page?.title} author={postAuthor} />
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
      </div>
      {posts && (
        <div className="relateditems grid md:grid-cols-3 gap-6 mt-20">
          {posts?.nodes.map((post: any) => {
            return (
              <RelatedItem
                key={post?.id}
                heading={post?.title}
                content={post?.content}
                to={post?.uri}
                date={post?.date}
                featuredType={post?.terms?.nodes}
              />
            )
          })}
        </div>
      )}
    </Layout>
  )
}
export const PageQuery = graphql`
  query PostById($id: String, $term: String, $idarr: [String]) {
    page: wpPost(id: { eq: $id }) {
      id
      content
      title
      uri
      date
      heroFilter: gwShowPostHeaderFilter
      postAuthorImage: gwPostAuthorImage {
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      postAuthorName: gwPostAuthor
      postAuthorContent: gwPostAuthorText
      ...postBlocks
      ...seoPost
      ...featuredHeroImagePost
    }
    wp {
      options: crbThemeOptions {
        archiveurl: gwArchiveUrl
        customBreadCrump: gwBreadcrumpsPost {
          link
        }
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    posts: allWpPost(
      limit: 3
      sort: { order: DESC, fields: date }
      filter: { id: { nin: $idarr }, terms: { nodes: { elemMatch: { name: { eq: $term } } } } }
    ) {
      ...allPostsExtended
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default SingleTemplate
